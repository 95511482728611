import React, { useRef } from 'react';
import { Button, TextInput, View } from 'dripsy';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet, Text } from 'react-native';

import {
  GetTasksDocument,
  GetTasksQuery,
  useCreateTaskMutation,
} from '../components/generated/ApolloComponents';
import { theme } from '../utils/constants';

interface Props {
  teamId: string;
}

export type CreateTaskFormData = {
  description: string;
};

export const CreateTaskForm: React.FC<Props> = ({ teamId }) => {
  const styles = StyleSheet.create({
    input: {
      color: theme.colors.primary,
      borderColor: '#666666',
      borderWidth: 1,
      // width: '100%',
      flex: 1,
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CreateTaskFormData>({
    defaultValues: {
      description: '',
    },
  });

  const [createTask] = useCreateTaskMutation();
  const inputRef = useRef<typeof TextInput>(null);

  const onSubmit = async (data: CreateTaskFormData) => {
    console.log('On Submit');
    const response = await createTask({
      variables: { input: { description: data.description, teamId } },
      update: (cache, { data }) => {
        const currentTasks = cache.readQuery<GetTasksQuery>({
          query: GetTasksDocument,
          variables: {
            teamId,
          },
        });
        cache.writeQuery<GetTasksQuery>({
          query: GetTasksDocument,
          variables: {
            teamId,
          },
          data: {
            ...currentTasks,
            __typename: 'Query',
            getTasks: [
              ...(currentTasks?.getTasks || []),
              data?.createTask.task!,
            ],
          },
        });
      },
    });
    if (response.data?.createTask.errors?.length) {
      console.error(response.data.createTask.errors);
    } else if (response.data?.createTask.task) {
      reset();
      if (inputRef.current != null) {
        // @ts-ignore
        inputRef.current.focus();
      }
    }
  };
  return (
    <View style={{ flexDirection: 'row', maxWidth: '100%' }}>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            ref={inputRef}
            style={styles.input}
            onBlur={onBlur}
            onChangeText={(value) => onChange(value)}
            placeholder={'task description'}
            value={value}
            onSubmitEditing={handleSubmit(onSubmit)}
            returnKeyType="go"
          />
        )}
        name="description"
        rules={{ required: true }}
        defaultValue=""
      />
      {errors.description && (
        <Text style={{ color: theme.colors.red }}>
          Description is required.
        </Text>
      )}

      <Button
        color={theme.colors.blue}
        title="Add task"
        onPress={handleSubmit(onSubmit)}
      />
    </View>
  );
};

import React from 'react';
import { Button, TextInput, View } from 'dripsy';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet, Text } from 'react-native';
import { Picker } from '@react-native-picker/picker';

import {
  GetTeamMembersQuery,
  GetTeamMembersDocument,
  PermissionLevel,
  useAddTeamMemberMutation,
} from '../components/generated/ApolloComponents';
import { theme } from '../utils/constants';

interface Props {
  teamId: string;
}

export type AddTeamMemberFormData = {
  email: string;
  permissionLevel: PermissionLevel;
};

export const AddTeamMemberForm: React.FC<Props> = ({ teamId }) => {
  const styles = StyleSheet.create({
    input: {
      color: theme.colors.primary,
      borderColor: '#666666',
      borderWidth: 1,
      width: '50%',
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<AddTeamMemberFormData>({
    defaultValues: {
      email: '',
      permissionLevel: PermissionLevel.Viewer,
    },
  });

  const [createTask] = useAddTeamMemberMutation();

  const onSubmit = async (data: AddTeamMemberFormData) => {
    const response = await createTask({
      variables: {
        input: {
          email: data.email,
          teamId,
          permissionLevel: data.permissionLevel,
        },
      },
      update: (cache, { data }) => {
        const currentTeamMembers = cache.readQuery<GetTeamMembersQuery>({
          query: GetTeamMembersDocument,
          variables: {
            teamId,
          },
        });
        console.log('CURRENT_TEAM_MEMBERS', currentTeamMembers);
        cache.writeQuery<GetTeamMembersQuery>({
          query: GetTeamMembersDocument,
          variables: {
            teamId,
          },
          data: {
            ...currentTeamMembers,
            __typename: 'Query',
            getTeamMembers: [
              ...(currentTeamMembers?.getTeamMembers || []),
              data?.addTeamMember!,
            ],
          },
        });
      },
    });
    if (response.errors) {
      console.error(response.errors);
    } else if (response.data?.addTeamMember.id) {
      reset();
    }
  };
  return (
    <View style={{ flexDirection: 'row' }}>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            style={styles.input}
            onBlur={onBlur}
            onChangeText={(value) => onChange(value)}
            placeholder={'email'}
            value={value}
          />
        )}
        name="email"
        rules={{ required: true }}
        defaultValue=""
      />
      {errors.email && (
        <Text style={{ color: theme.colors.red }}>Email is required.</Text>
      )}

      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          // <TextInput
          //   style={styles.input}
          //   onBlur={onBlur}
          //   onChangeText={(value) => onChange(value)}
          //   placeholder={'permission level'}
          //   value={value}
          // />
          <Picker
            style={{
              backgroundColor: theme.colors.background,
              color: theme.colors.primary,
              width: '50%',
            }}
            onValueChange={(value: PermissionLevel) => onChange(value)}
            selectedValue={value}
          >
            <Picker.Item
              style={{ color: theme.colors.primary }}
              label={'Viewer'}
              value={PermissionLevel.Viewer}
            />
            <Picker.Item
              style={{ color: theme.colors.primary }}
              label={'Editor'}
              value={PermissionLevel.Editor}
            />
            <Picker.Item
              style={{ color: theme.colors.primary }}
              label={'Admin'}
              value={PermissionLevel.Admin}
            />
            <Picker.Item
              style={{ color: theme.colors.primary }}
              label={'Owner'}
              value={PermissionLevel.Owner}
            />
          </Picker>
        )}
        name="permissionLevel"
        rules={{ required: true }}
        defaultValue={PermissionLevel.Viewer}
      />
      {errors.permissionLevel && (
        <Text style={{ color: theme.colors.red }}>
          Permission level is required.
        </Text>
      )}

      <Button
        color={theme.colors.blue}
        title="Add team member"
        onPress={handleSubmit(onSubmit)}
      />
    </View>
  );
};

import React from 'react';
import { Text, View } from 'dripsy';
import { useApolloClient } from '@apollo/client';
import { Link } from '@react-navigation/native';

import { MeDocument, useLogoutMutation } from '../generated/ApolloComponents';
import { TouchableOpacity } from 'react-native';
import { theme } from '../../utils/constants';

interface Props {}

export const Header: React.FC<Props> = () => {
  const [logout, { loading: logoutFetching }] = useLogoutMutation();
  const apolloClient = useApolloClient();
  const meData = apolloClient.readQuery({ query: MeDocument });
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {/* <H1 sx={{ color: textColor }}>Practical Magic</H1> */}
      <View style={{ marginLeft: 'auto', flexDirection: 'row' }}>
        {meData && meData.me && meData.me.id ? (
          <Link to={`/${meData.me.id}/profile`}>
            <Text style={{ color: 'silver' }}>({meData.me.username})</Text>
          </Link>
        ) : null}
        <TouchableOpacity
          onPress={async () => {
            await logout();
            await apolloClient.resetStore();
          }}
        >
          <Text style={{ color: 'gray' }}> logout</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

import React from 'react';
import { Text, View } from 'react-native';
import { Hoverable } from 'react-native-web-hooks';

import {
  TeamMembershipFragmentFragment,
  useGetTeamMembersQuery,
} from '../components/generated/ApolloComponents';
import { AddTeamMemberForm } from '../forms/AddTeamMember';
import { LoadingScreen } from '../screens/LoadingScreen';
import { theme } from '../utils/constants';

interface Props {
  teamId: string;
}

export const TeamMembersContainer: React.FC<Props> = ({ teamId }) => {
  const TeamMemberItem = (teamMember: TeamMembershipFragmentFragment) => (
    <Hoverable>
      {(isHovered) => (
        <View
          style={{
            flexDirection: 'row',
            borderColor: 'silver',
            borderWidth: isHovered ? 1 : 0,
          }}
        >
          <Text style={{ color: theme.colors.primary }}>
            {teamMember.user.email}
          </Text>
          <Text style={{ color: theme.colors.secondary, marginLeft: 'auto' }}>
            {teamMember.permissionLevel}
          </Text>
        </View>
      )}
    </Hoverable>
  );
  const { data, loading, error } = useGetTeamMembersQuery({
    variables: { teamId },
  });
  if (loading || !data) {
    return <LoadingScreen />;
  }
  if (error) {
    return (
      <View>
        <Text style={{ color: theme.colors.primary }}>{error.message}</Text>
      </View>
    );
  }
  return (
    <View style={{ borderColor: 'silver', borderWidth: 2 }}>
      {data
        ? data.getTeamMembers.map((t) => <TeamMemberItem key={t.id} {...t} />)
        : null}
      <AddTeamMemberForm teamId={teamId} />
    </View>
  );
};

import 'react-native-gesture-handler';
import React from 'react';
import { StyleSheet } from 'react-native';
import { DripsyProvider, View } from '@dripsy/core';
import { ApolloProvider } from '@apollo/client/react';
import { NavigationContainer } from '@react-navigation/native';
import { StatusBar } from 'expo-status-bar';

import { DashboardScreen } from './src/screens/DashboardScreen';
import { AuthenticationScreen } from './src/screens/AuthenticationScreen';
import { useMeQuery } from './src/components/generated/ApolloComponents';
import { client } from './src/libs/apollo';
import { MainContainer } from './src/containers/MainContainer';
import Navigator from './src/navigators';
import { theme } from './src/utils/constants';

export default function App() {
  return (
    <ApolloProvider client={client}>
      <DripsyProvider theme={theme}>
        <View style={styles.container}>
          <Navigator />
          <StatusBar style="auto" />
        </View>
      </DripsyProvider>
    </ApolloProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    // flexDirection: 'column',
    backgroundColor: '#000',
    color: theme.colors.primary,
    // alignItems: 'center',
    // justifyContent: 'center',
  },
});

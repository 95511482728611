import React, { useState } from 'react';
import { Button, Text, View } from 'react-native';
import { Picker } from '@react-native-picker/picker';

import {
  TaskFragmentFragment,
  useGetTeamMembersQuery,
  useUpdateTaskMutation,
} from '../components/generated/ApolloComponents';
import { LoadingScreen } from '../screens/LoadingScreen';
import { theme } from '../utils/constants';

interface Props {
  task: TaskFragmentFragment;
  setDisplayUserAssignmentPicker: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AssignTaskToUser: React.FC<Props> = ({
  task,
  setDisplayUserAssignmentPicker,
}) => {
  const [updateTask] = useUpdateTaskMutation();

  const { data, loading, error } = useGetTeamMembersQuery({
    variables: { teamId: task.teamId },
  });
  if (!data || loading) {
    return <LoadingScreen />;
  }
  const [teamMembers, setTeamMembers] = useState(data);
  const [selectedUserId, setSelectedUserId] = useState<string>(
    task.assignedUser?.id
      ? task.assignedUser.id
      : teamMembers
      ? teamMembers.getTeamMembers[0].userId
      : ''
  );
  return (
    <View style={{ marginLeft: 'auto', flexDirection: 'row' }}>
      <Picker
        onValueChange={(value: string) => setSelectedUserId(value)}
        selectedValue={selectedUserId}
        style={{
          backgroundColor: theme.colors.background,
          color: theme.colors.primary,
          width: '100%',
        }}
      >
        {teamMembers?.getTeamMembers.map((tm) => (
          <Picker.Item
            style={{ color: theme.colors.primary }}
            key={tm.id}
            label={tm.user.email}
            value={tm.user.id}
          />
        ))}
      </Picker>
      <Button
        color={theme.colors.blue}
        onPress={async () => {
          const res = await updateTask({
            variables: {
              taskId: task.id,
              input: {
                assignedUserId: selectedUserId,
              },
            },
          });
          if (res.data?.updateTask.success) {
            setDisplayUserAssignmentPicker(false);
          }
        }}
        title="Save"
      />
      <Text
        onPress={() => {
          setDisplayUserAssignmentPicker(false);
        }}
        style={{
          color: theme.colors.red,
          fontSize: 24,
          paddingHorizontal: 10,
          fontFamily: 'System',
        }}
      >
        X
      </Text>
    </View>
  );
};

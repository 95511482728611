import React, { useEffect, useRef } from 'react';
import { Button, TextInput, Text } from 'dripsy';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';

import { theme } from '../utils/constants';
import {
  GetTasksDocument,
  GetTasksQuery,
  TaskFragmentFragment,
  useUpdateTaskMutation,
} from '../components/generated/ApolloComponents';

interface Props {
  task: TaskFragmentFragment;
  setDisplayDescriptionInput: React.Dispatch<React.SetStateAction<boolean>>;
  displayDescriptionInput: boolean;
}

export type UpdateTaskDesciptionFormData = {
  description: string;
};

export const UpdateTaskDesciptionForm: React.FC<Props> = ({
  task,
  setDisplayDescriptionInput,
  displayDescriptionInput,
}) => {
  const styles = StyleSheet.create({
    input: {
      color: theme.colors.primary,
      borderColor: '#666666',
      borderWidth: 1,
      width: '100%',
    },
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<UpdateTaskDesciptionFormData>({
    defaultValues: {
      description: task.description ? task.description : '',
    },
  });

  const [updateTask] = useUpdateTaskMutation();
  const inputRef = useRef<typeof TextInput>(null);

  useEffect(() => {
    if (inputRef.current !== null && displayDescriptionInput === true) {
      // @ts-ignore
      inputRef.current.focus();
    }
  }, [displayDescriptionInput]);

  const onSubmit = async (data: UpdateTaskDesciptionFormData) => {
    const res = await updateTask({
      variables: {
        taskId: task.id,
        input: {
          description: data.description,
          assignedUserId: task.assignedUserId,
        },
      },
    });
    if (res.data?.updateTask.success) {
      reset();
      setDisplayDescriptionInput(false);
    }
  };

  return (
    <View style={{ flexDirection: 'row', width: '100%', flex: 1 }}>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            ref={inputRef}
            style={styles.input}
            onBlur={onBlur}
            onChangeText={(value) => onChange(value)}
            placeholder={'task description'}
            value={value}
            returnKeyType="done"
            onSubmitEditing={handleSubmit(onSubmit)}
            onKeyPress={(e) => {
              if (e.nativeEvent.key === 'Escape') {
                reset();
                setDisplayDescriptionInput(false);
              }
            }}
          />
        )}
        name="description"
        rules={{ required: true }}
        defaultValue={task.description}
      />
      {errors.description && (
        <Text style={{ color: theme.colors.red }}>
          Description is required.
        </Text>
      )}
      <Button
        color={theme.colors.blue}
        title="Save description"
        onPress={handleSubmit(onSubmit)}
      />
      <Text
        onPress={() => {
          reset();
          setDisplayDescriptionInput(false);
        }}
        style={{ color: theme.colors.red, fontSize: 24 }}
      >
        X
      </Text>
    </View>
  );
};

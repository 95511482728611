export const textColor = '#FFBF00';

export const theme = {
  colors: {
    primary: '#FFBF00',
    text: '#FFBF00',
    secondary: 'white',
    background: '#000',
    red: 'red',
    green: 'green',
    blue: 'blue',
  },
};

import React, { useEffect, useRef } from 'react';
import { Button, TextInput, Text } from 'dripsy';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';

import { theme } from '../utils/constants';
import {
  GoalFragmentFragment,
  useUpdateGoalMutation,
} from '../components/generated/ApolloComponents';

interface Props {
  goal: GoalFragmentFragment;
  setDisplayGoalDescriptionInput: React.Dispatch<React.SetStateAction<boolean>>;
  displayGoalDescriptionInput: boolean;
}

export type UpdateGoalDesciptionFormData = {
  description: string;
};

export const UpdateGoalDesciptionForm: React.FC<Props> = ({
  goal,
  setDisplayGoalDescriptionInput,
  displayGoalDescriptionInput,
}) => {
  const styles = StyleSheet.create({
    input: {
      color: theme.colors.primary,
      borderColor: '#666666',
      borderWidth: 1,
      width: '100%',
    },
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<UpdateGoalDesciptionFormData>({
    defaultValues: {
      description: goal.description ? goal.description : '',
    },
  });

  const [updateGoal] = useUpdateGoalMutation();
  const inputRef = useRef<typeof TextInput>(null);

  useEffect(() => {
    if (inputRef.current !== null && displayGoalDescriptionInput === true) {
      // @ts-ignore
      inputRef.current.focus();
    }
  }, [displayGoalDescriptionInput]);

  const onSubmit = async (data: UpdateGoalDesciptionFormData) => {
    const res = await updateGoal({
      variables: {
        goalId: goal.id,
        description: data.description,
      },
    });
    if (res.data?.updateGoal.success) {
      reset();
      setDisplayGoalDescriptionInput(false);
    }
  };

  return (
    <View style={{ flexDirection: 'row', width: '100%', flex: 1 }}>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            ref={inputRef}
            style={styles.input}
            onBlur={onBlur}
            onChangeText={(value) => onChange(value)}
            placeholder={'task description'}
            onSubmitEditing={handleSubmit(onSubmit)}
            value={value}
            onKeyPress={(e) => {
              if (e.nativeEvent.key === 'Escape') {
                reset();
                setDisplayGoalDescriptionInput(false);
              }
            }}
          />
        )}
        name="description"
        rules={{ required: true }}
        defaultValue={goal.description}
      />
      {errors.description && (
        <Text style={{ color: theme.colors.red }}>
          Description is required.
        </Text>
      )}
      <Button
        color={theme.colors.blue}
        title="Save description"
        onPress={handleSubmit(onSubmit)}
      />
      <Text
        onPress={() => {
          reset();
          setDisplayGoalDescriptionInput(false);
        }}
        style={{ color: theme.colors.red, fontSize: 24 }}
      >
        X
      </Text>
    </View>
  );
};

import React from 'react';
import { View } from 'react-native';

import { TaskFragmentFragment } from '../../components/generated/ApolloComponents';
import { TaskItem } from './TaskItem';

interface Props {
  tasks: TaskFragmentFragment[];
}

export const TasksSection: React.FC<Props> = ({ tasks }) => {
  return (
    <View>
      {tasks
        ? tasks
            .filter((t) => !t.isArchived)
            .map((t) => <TaskItem key={t.id} task={t} />)
        : null}
    </View>
  );
};

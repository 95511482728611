import React from 'react';
import { RouteProp } from '@react-navigation/native';
import { Header } from '../components/common/Header';

import { Screen } from '../components/common/Screen';
import { TeamsContainer } from '../containers/TeamsContainer';
import { StackParamList } from '../navigators';

interface Props {
  route: RouteProp<StackParamList, 'Profile'>;
}

export const ProfileScreen: React.FC<Props> = ({ route }) => {
  return (
    <Screen style={{ paddingHorizontal: 10 }}>
      <Header />
      <TeamsContainer userId={route.params.userId} />
    </Screen>
  );
};

import React from 'react';
import { Link } from '@react-navigation/native';

import { Screen } from '../components/common/Screen';
import { RegisterForm } from '../forms/RegisterForm';
import { theme } from '../utils/constants';

interface Props {}

export const RegisterScreen: React.FC<Props> = () => {
  return (
    <Screen style={{ justifyContent: 'center', alignItems: 'center' }}>
      <RegisterForm />
      <Link style={{ color: theme.colors.primary }} to={'/login'}>
        Already have an account? Login here
      </Link>
    </Screen>
  );
};

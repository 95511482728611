import React from 'react';
import { Button, TextInput, View } from 'dripsy';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet, Text } from 'react-native';

import {
  GetGoalsDocument,
  GetGoalsQuery,
  useCreateGoalMutation,
} from '../components/generated/ApolloComponents';
import { theme } from '../utils/constants';

interface Props {
  teamId: string;
}

export type CreateGoalFormData = {
  description: string;
};

export const CreateGoalForm: React.FC<Props> = ({ teamId }) => {
  const styles = StyleSheet.create({
    input: {
      color: theme.colors.primary,
      borderColor: '#666666',
      borderWidth: 1,
      width: '100%',
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CreateGoalFormData>({
    defaultValues: {
      description: '',
    },
  });

  const [createGoal] = useCreateGoalMutation();

  const onSubmit = async (data: CreateGoalFormData) => {
    const response = await createGoal({
      variables: { description: data.description, teamId },
      update: (cache, { data }) => {
        const currentGoals = cache.readQuery<GetGoalsQuery>({
          query: GetGoalsDocument,
          variables: {
            teamId,
          },
        });
        cache.writeQuery<GetGoalsQuery>({
          query: GetGoalsDocument,
          variables: {
            teamId,
          },
          data: {
            ...currentGoals,
            __typename: 'Query',
            getGoals: [
              ...(currentGoals?.getGoals || []),
              data?.createGoal.goal!,
            ],
          },
        });
      },
    });
    if (response.data?.createGoal.errors?.length) {
      console.error(response.data.createGoal.errors);
    } else if (response.data?.createGoal.goal) {
      reset();
    }
  };
  return (
    <View style={{ flexDirection: 'row' }}>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            style={styles.input}
            onBlur={onBlur}
            onChangeText={(value) => onChange(value)}
            placeholder={'goal description'}
            value={value}
            onSubmitEditing={handleSubmit(onSubmit)}
          />
        )}
        name="description"
        rules={{ required: true }}
        defaultValue=""
      />
      {errors.description && (
        <Text style={{ color: theme.colors.red }}>
          Description is required.
        </Text>
      )}

      <Button
        title="Add goal"
        color={theme.colors.blue}
        onPress={handleSubmit(onSubmit)}
      />
    </View>
  );
};

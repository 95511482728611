import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type AddTeamMemberInput = {
  teamId: Scalars['String'];
  email: Scalars['String'];
  permissionLevel: PermissionLevel;
};

export type CreateTaskInput = {
  description: Scalars['String'];
  isComplete?: Maybe<Scalars['Boolean']>;
  teamId: Scalars['String'];
  assignedUserId?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  tagIds?: Maybe<Array<Scalars['String']>>;
};

export type CreateUpdateGoalResponse = {
  __typename?: 'CreateUpdateGoalResponse';
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
  goal?: Maybe<Goal>;
};

export type CreateUpdateTaskResponse = {
  __typename?: 'CreateUpdateTaskResponse';
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
  task?: Maybe<Task>;
};

export type CreateUpdateTeamMembershipResponse = {
  __typename?: 'CreateUpdateTeamMembershipResponse';
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
  teamMembership?: Maybe<TeamMembership>;
};

export type CustomBaseEntity = {
  __typename?: 'CustomBaseEntity';
  id: Scalars['ID'];
  shortLink?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Float']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};


export type Error = {
  __typename?: 'Error';
  message: Scalars['String'];
  path?: Maybe<Scalars['String']>;
};

export type ErrorResponse = {
  __typename?: 'ErrorResponse';
  errors?: Maybe<Array<Error>>;
};

export type ForgotPasswordChangeInput = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};

export type Goal = {
  __typename?: 'Goal';
  id: Scalars['ID'];
  shortLink?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Float']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  description: Scalars['String'];
  teamId: Scalars['String'];
  isComplete?: Maybe<Scalars['Boolean']>;
  team: Team;
  creatorId: Scalars['String'];
  creator: User;
};

export type LoginInput = {
  usernameOrEmail: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  archiveGoal: CreateUpdateGoalResponse;
  createGoal: CreateUpdateGoalResponse;
  updateGoal: CreateUpdateGoalResponse;
  createTag?: Maybe<Tag>;
  archiveTask: CreateUpdateTaskResponse;
  createTask: CreateUpdateTaskResponse;
  updateTask: CreateUpdateTaskResponse;
  addTeamMember: TeamMembership;
  archiveTeam: StandardResponse;
  createTeam: CreateUpdateTeamMembershipResponse;
  sendForgotPasswordEmail: Scalars['Boolean'];
  forgotPasswordChange: ErrorResponse;
  login: UserResponse;
  logout: Scalars['Boolean'];
  register: UserResponse;
  subscribe: StandardResponse;
  unsubscribe: StandardResponse;
};


export type MutationArchiveGoalArgs = {
  archive: Scalars['Boolean'];
  goalId: Scalars['String'];
};


export type MutationCreateGoalArgs = {
  teamId: Scalars['String'];
  description: Scalars['String'];
};


export type MutationUpdateGoalArgs = {
  isComplete?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  goalId: Scalars['String'];
};


export type MutationCreateTagArgs = {
  title: Scalars['String'];
};


export type MutationArchiveTaskArgs = {
  taskId: Scalars['String'];
  archive: Scalars['Boolean'];
};


export type MutationCreateTaskArgs = {
  input: CreateTaskInput;
};


export type MutationUpdateTaskArgs = {
  taskId: Scalars['String'];
  input?: Maybe<UpdateTaskInput>;
};


export type MutationAddTeamMemberArgs = {
  input: AddTeamMemberInput;
};


export type MutationArchiveTeamArgs = {
  archive: Scalars['Boolean'];
  teamId: Scalars['String'];
};


export type MutationCreateTeamArgs = {
  name: Scalars['String'];
};


export type MutationSendForgotPasswordEmailArgs = {
  email: Scalars['String'];
};


export type MutationForgotPasswordChangeArgs = {
  input: ForgotPasswordChangeInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationRegisterArgs = {
  input: RegisterInput;
};


export type MutationSubscribeArgs = {
  name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};


export type MutationUnsubscribeArgs = {
  email: Scalars['String'];
};

export type PaginatedTasks = {
  __typename?: 'PaginatedTasks';
  tasks: Array<Task>;
  hasMore: Scalars['Boolean'];
};

/** Permission level for team membership */
export enum PermissionLevel {
  Owner = 'OWNER',
  Admin = 'ADMIN',
  Editor = 'EDITOR',
  Viewer = 'VIEWER'
}

export type Query = {
  __typename?: 'Query';
  getGoal: Goal;
  getGoals: Array<Goal>;
  getTagId: Scalars['String'];
  getTask: Task;
  getTasks: Array<Task>;
  searchTasks: PaginatedTasks;
  getTeamMembers: Array<TeamMembership>;
  getTeam: Team;
  getTeams: Array<TeamMembership>;
  me?: Maybe<User>;
};


export type QueryGetGoalArgs = {
  goalId: Scalars['String'];
};


export type QueryGetGoalsArgs = {
  teamId: Scalars['String'];
  includeArchived?: Maybe<Scalars['Boolean']>;
};


export type QueryGetTagIdArgs = {
  title: Scalars['String'];
};


export type QueryGetTaskArgs = {
  taskId: Scalars['String'];
};


export type QueryGetTasksArgs = {
  teamId: Scalars['String'];
  includeArchived?: Maybe<Scalars['Boolean']>;
};


export type QuerySearchTasksArgs = {
  includeArchived?: Maybe<Scalars['Boolean']>;
  cursor?: Maybe<Scalars['Int']>;
  queryArray?: Maybe<Array<Scalars['String']>>;
  query?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
};


export type QueryGetTeamMembersArgs = {
  teamId: Scalars['String'];
};


export type QueryGetTeamArgs = {
  teamId: Scalars['String'];
};


export type QueryGetTeamsArgs = {
  userId: Scalars['String'];
};

export type RegisterInput = {
  username: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type StandardResponse = {
  __typename?: 'StandardResponse';
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  shortLink?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Float']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
};

export type Task = {
  __typename?: 'Task';
  id: Scalars['ID'];
  shortLink?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Float']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  taskNumber: Scalars['Float'];
  description: Scalars['String'];
  isComplete?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  creatorId: Scalars['String'];
  teamId: Scalars['String'];
  assignedUserId?: Maybe<Scalars['String']>;
  creator: User;
  assignedUser?: Maybe<User>;
  team: Team;
  tags?: Maybe<Array<Tag>>;
};

export type Team = {
  __typename?: 'Team';
  id: Scalars['ID'];
  shortLink?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Float']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  owners?: Maybe<Array<User>>;
  tasks?: Maybe<Array<Task>>;
  goals?: Maybe<Array<Goal>>;
  memberConnection?: Maybe<Array<TeamMembership>>;
  members?: Maybe<Array<User>>;
};

export type TeamMembership = {
  __typename?: 'TeamMembership';
  id: Scalars['ID'];
  shortLink?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Float']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  userId: Scalars['String'];
  teamId: Scalars['String'];
  permissionLevel: PermissionLevel;
  user: User;
  team: Team;
};

export type UpdateTaskInput = {
  description?: Maybe<Scalars['String']>;
  isComplete?: Maybe<Scalars['Boolean']>;
  teamId?: Maybe<Scalars['String']>;
  assignedUserId?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  tagIds?: Maybe<Array<Scalars['String']>>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  shortLink?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Float']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  username?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  userType: Scalars['String'];
  confirmed: Scalars['Boolean'];
  isEmailSubscriber: Scalars['Boolean'];
  accountLocked: Scalars['Boolean'];
  requirePasswordReset: Scalars['Boolean'];
  loginCount: Scalars['Float'];
  defaultTeamId?: Maybe<Scalars['String']>;
  teamsOwned?: Maybe<Array<Team>>;
  teamConnection?: Maybe<Array<TeamMembership>>;
  teams?: Maybe<Array<Team>>;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

export type GoalFragmentFragment = (
  { __typename?: 'Goal' }
  & Pick<Goal, 'id' | 'description' | 'createdDate' | 'updatedDate' | 'creatorId' | 'isComplete' | 'isArchived'>
);

export type ArchiveGoalMutationVariables = Exact<{
  archive: Scalars['Boolean'];
  goalId: Scalars['String'];
}>;


export type ArchiveGoalMutation = (
  { __typename?: 'Mutation' }
  & { archiveGoal: (
    { __typename?: 'CreateUpdateGoalResponse' }
    & Pick<CreateUpdateGoalResponse, 'success'>
    & { goal?: Maybe<(
      { __typename?: 'Goal' }
      & GoalFragmentFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'message'>
    )>> }
  ) }
);

export type CreateGoalMutationVariables = Exact<{
  description: Scalars['String'];
  teamId: Scalars['String'];
}>;


export type CreateGoalMutation = (
  { __typename?: 'Mutation' }
  & { createGoal: (
    { __typename?: 'CreateUpdateGoalResponse' }
    & Pick<CreateUpdateGoalResponse, 'success'>
    & { goal?: Maybe<(
      { __typename?: 'Goal' }
      & GoalFragmentFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'message'>
    )>> }
  ) }
);

export type UpdateGoalMutationVariables = Exact<{
  description?: Maybe<Scalars['String']>;
  goalId: Scalars['String'];
  isComplete?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateGoalMutation = (
  { __typename?: 'Mutation' }
  & { updateGoal: (
    { __typename?: 'CreateUpdateGoalResponse' }
    & Pick<CreateUpdateGoalResponse, 'success'>
    & { goal?: Maybe<(
      { __typename?: 'Goal' }
      & GoalFragmentFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'message'>
    )>> }
  ) }
);

export type GetGoalQueryVariables = Exact<{
  goalId: Scalars['String'];
}>;


export type GetGoalQuery = (
  { __typename?: 'Query' }
  & { getGoal: (
    { __typename?: 'Goal' }
    & GoalFragmentFragment
  ) }
);

export type GetGoalsQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;


export type GetGoalsQuery = (
  { __typename?: 'Query' }
  & { getGoals: Array<(
    { __typename?: 'Goal' }
    & GoalFragmentFragment
  )> }
);

export type StandardErrorFragment = (
  { __typename?: 'Error' }
  & Pick<Error, 'path' | 'message'>
);

export type StandardResponseFragment = (
  { __typename?: 'StandardResponse' }
  & Pick<StandardResponse, 'success'>
  & { errors?: Maybe<Array<(
    { __typename?: 'Error' }
    & StandardErrorFragment
  )>> }
);

export type TaskFragmentFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'description' | 'link' | 'isComplete' | 'assignedUserId' | 'createdDate' | 'updatedDate' | 'creatorId' | 'teamId' | 'isArchived'>
  & { assignedUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'username'>
  )> }
);

export type ArchiveTaskMutationVariables = Exact<{
  archive: Scalars['Boolean'];
  taskId: Scalars['String'];
}>;


export type ArchiveTaskMutation = (
  { __typename?: 'Mutation' }
  & { archiveTask: (
    { __typename?: 'CreateUpdateTaskResponse' }
    & Pick<CreateUpdateTaskResponse, 'success'>
    & { task?: Maybe<(
      { __typename?: 'Task' }
      & TaskFragmentFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'message'>
    )>> }
  ) }
);

export type CreateTaskMutationVariables = Exact<{
  input: CreateTaskInput;
}>;


export type CreateTaskMutation = (
  { __typename?: 'Mutation' }
  & { createTask: (
    { __typename?: 'CreateUpdateTaskResponse' }
    & Pick<CreateUpdateTaskResponse, 'success'>
    & { task?: Maybe<(
      { __typename?: 'Task' }
      & TaskFragmentFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'message'>
    )>> }
  ) }
);

export type UpdateTaskMutationVariables = Exact<{
  input: UpdateTaskInput;
  taskId: Scalars['String'];
}>;


export type UpdateTaskMutation = (
  { __typename?: 'Mutation' }
  & { updateTask: (
    { __typename?: 'CreateUpdateTaskResponse' }
    & Pick<CreateUpdateTaskResponse, 'success'>
    & { task?: Maybe<(
      { __typename?: 'Task' }
      & TaskFragmentFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'message'>
    )>> }
  ) }
);

export type GetTaskQueryVariables = Exact<{
  taskId: Scalars['String'];
}>;


export type GetTaskQuery = (
  { __typename?: 'Query' }
  & { getTask: (
    { __typename?: 'Task' }
    & TaskFragmentFragment
  ) }
);

export type GetTasksQueryVariables = Exact<{
  teamId: Scalars['String'];
  includeArchived?: Maybe<Scalars['Boolean']>;
}>;


export type GetTasksQuery = (
  { __typename?: 'Query' }
  & { getTasks: Array<(
    { __typename?: 'Task' }
    & TaskFragmentFragment
  )> }
);

export type TeamMembershipFragmentFragment = (
  { __typename?: 'TeamMembership' }
  & Pick<TeamMembership, 'id' | 'permissionLevel' | 'userId' | 'teamId'>
  & { team: (
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'name'>
  ), user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'username'>
  ) }
);

export type AddTeamMemberMutationVariables = Exact<{
  input: AddTeamMemberInput;
}>;


export type AddTeamMemberMutation = (
  { __typename?: 'Mutation' }
  & { addTeamMember: (
    { __typename?: 'TeamMembership' }
    & TeamMembershipFragmentFragment
  ) }
);

export type GetTeamMembersQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;


export type GetTeamMembersQuery = (
  { __typename?: 'Query' }
  & { getTeamMembers: Array<(
    { __typename?: 'TeamMembership' }
    & TeamMembershipFragmentFragment
  )> }
);

export type TeamFragmentFragment = (
  { __typename?: 'Team' }
  & Pick<Team, 'id' | 'name' | 'isArchived'>
);

export type CreateTeamMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateTeamMutation = (
  { __typename?: 'Mutation' }
  & { createTeam: (
    { __typename?: 'CreateUpdateTeamMembershipResponse' }
    & Pick<CreateUpdateTeamMembershipResponse, 'success'>
    & { teamMembership?: Maybe<(
      { __typename?: 'TeamMembership' }
      & TeamMembershipFragmentFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'message'>
    )>> }
  ) }
);

export type GetTeamQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;


export type GetTeamQuery = (
  { __typename?: 'Query' }
  & { getTeam: (
    { __typename?: 'Team' }
    & TeamFragmentFragment
  ) }
);

export type GetTeamsQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetTeamsQuery = (
  { __typename?: 'Query' }
  & { getTeams: Array<(
    { __typename?: 'TeamMembership' }
    & TeamMembershipFragmentFragment
  )> }
);

export type UserInfoFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'username' | 'email' | 'userType' | 'defaultTeamId'>
);

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'UserResponse' }
    & StandardUserResponseFragment
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type RegisterMutationVariables = Exact<{
  input: RegisterInput;
}>;


export type RegisterMutation = (
  { __typename?: 'Mutation' }
  & { register: (
    { __typename?: 'UserResponse' }
    & StandardUserResponseFragment
  ) }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & UserInfoFragment
  )> }
);

export type StandardUserResponseFragment = (
  { __typename?: 'UserResponse' }
  & Pick<UserResponse, 'success'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserInfoFragment
  )>, errors?: Maybe<Array<(
    { __typename?: 'Error' }
    & StandardErrorFragment
  )>> }
);

export const GoalFragmentFragmentDoc = gql`
    fragment GoalFragment on Goal {
  id
  description
  createdDate
  updatedDate
  creatorId
  isComplete
  isArchived
}
    `;
export const StandardErrorFragmentDoc = gql`
    fragment StandardError on Error {
  path
  message
}
    `;
export const StandardResponseFragmentDoc = gql`
    fragment StandardResponse on StandardResponse {
  errors {
    ...StandardError
  }
  success
}
    ${StandardErrorFragmentDoc}`;
export const TaskFragmentFragmentDoc = gql`
    fragment TaskFragment on Task {
  id
  description
  link
  isComplete
  assignedUserId
  assignedUser {
    id
    email
    username
  }
  createdDate
  updatedDate
  creatorId
  teamId
  isArchived
}
    `;
export const TeamMembershipFragmentFragmentDoc = gql`
    fragment TeamMembershipFragment on TeamMembership {
  id
  permissionLevel
  userId
  teamId
  team {
    id
    name
  }
  user {
    id
    email
    username
  }
}
    `;
export const TeamFragmentFragmentDoc = gql`
    fragment TeamFragment on Team {
  id
  name
  isArchived
}
    `;
export const UserInfoFragmentDoc = gql`
    fragment UserInfo on User {
  id
  username
  email
  userType
  defaultTeamId
}
    `;
export const StandardUserResponseFragmentDoc = gql`
    fragment StandardUserResponse on UserResponse {
  user {
    ...UserInfo
  }
  errors {
    ...StandardError
  }
  success
}
    ${UserInfoFragmentDoc}
${StandardErrorFragmentDoc}`;
export const ArchiveGoalDocument = gql`
    mutation ArchiveGoal($archive: Boolean!, $goalId: String!) {
  archiveGoal(archive: $archive, goalId: $goalId) {
    goal {
      ...GoalFragment
    }
    errors {
      path
      message
    }
    success
  }
}
    ${GoalFragmentFragmentDoc}`;
export type ArchiveGoalMutationFn = Apollo.MutationFunction<ArchiveGoalMutation, ArchiveGoalMutationVariables>;

/**
 * __useArchiveGoalMutation__
 *
 * To run a mutation, you first call `useArchiveGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveGoalMutation, { data, loading, error }] = useArchiveGoalMutation({
 *   variables: {
 *      archive: // value for 'archive'
 *      goalId: // value for 'goalId'
 *   },
 * });
 */
export function useArchiveGoalMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveGoalMutation, ArchiveGoalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveGoalMutation, ArchiveGoalMutationVariables>(ArchiveGoalDocument, options);
      }
export type ArchiveGoalMutationHookResult = ReturnType<typeof useArchiveGoalMutation>;
export type ArchiveGoalMutationResult = Apollo.MutationResult<ArchiveGoalMutation>;
export type ArchiveGoalMutationOptions = Apollo.BaseMutationOptions<ArchiveGoalMutation, ArchiveGoalMutationVariables>;
export const CreateGoalDocument = gql`
    mutation CreateGoal($description: String!, $teamId: String!) {
  createGoal(description: $description, teamId: $teamId) {
    goal {
      ...GoalFragment
    }
    errors {
      path
      message
    }
    success
  }
}
    ${GoalFragmentFragmentDoc}`;
export type CreateGoalMutationFn = Apollo.MutationFunction<CreateGoalMutation, CreateGoalMutationVariables>;

/**
 * __useCreateGoalMutation__
 *
 * To run a mutation, you first call `useCreateGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGoalMutation, { data, loading, error }] = useCreateGoalMutation({
 *   variables: {
 *      description: // value for 'description'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useCreateGoalMutation(baseOptions?: Apollo.MutationHookOptions<CreateGoalMutation, CreateGoalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGoalMutation, CreateGoalMutationVariables>(CreateGoalDocument, options);
      }
export type CreateGoalMutationHookResult = ReturnType<typeof useCreateGoalMutation>;
export type CreateGoalMutationResult = Apollo.MutationResult<CreateGoalMutation>;
export type CreateGoalMutationOptions = Apollo.BaseMutationOptions<CreateGoalMutation, CreateGoalMutationVariables>;
export const UpdateGoalDocument = gql`
    mutation UpdateGoal($description: String, $goalId: String!, $isComplete: Boolean) {
  updateGoal(description: $description, goalId: $goalId, isComplete: $isComplete) {
    goal {
      ...GoalFragment
    }
    errors {
      path
      message
    }
    success
  }
}
    ${GoalFragmentFragmentDoc}`;
export type UpdateGoalMutationFn = Apollo.MutationFunction<UpdateGoalMutation, UpdateGoalMutationVariables>;

/**
 * __useUpdateGoalMutation__
 *
 * To run a mutation, you first call `useUpdateGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGoalMutation, { data, loading, error }] = useUpdateGoalMutation({
 *   variables: {
 *      description: // value for 'description'
 *      goalId: // value for 'goalId'
 *      isComplete: // value for 'isComplete'
 *   },
 * });
 */
export function useUpdateGoalMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGoalMutation, UpdateGoalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGoalMutation, UpdateGoalMutationVariables>(UpdateGoalDocument, options);
      }
export type UpdateGoalMutationHookResult = ReturnType<typeof useUpdateGoalMutation>;
export type UpdateGoalMutationResult = Apollo.MutationResult<UpdateGoalMutation>;
export type UpdateGoalMutationOptions = Apollo.BaseMutationOptions<UpdateGoalMutation, UpdateGoalMutationVariables>;
export const GetGoalDocument = gql`
    query GetGoal($goalId: String!) {
  getGoal(goalId: $goalId) {
    ...GoalFragment
  }
}
    ${GoalFragmentFragmentDoc}`;

/**
 * __useGetGoalQuery__
 *
 * To run a query within a React component, call `useGetGoalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoalQuery({
 *   variables: {
 *      goalId: // value for 'goalId'
 *   },
 * });
 */
export function useGetGoalQuery(baseOptions: Apollo.QueryHookOptions<GetGoalQuery, GetGoalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGoalQuery, GetGoalQueryVariables>(GetGoalDocument, options);
      }
export function useGetGoalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGoalQuery, GetGoalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGoalQuery, GetGoalQueryVariables>(GetGoalDocument, options);
        }
export type GetGoalQueryHookResult = ReturnType<typeof useGetGoalQuery>;
export type GetGoalLazyQueryHookResult = ReturnType<typeof useGetGoalLazyQuery>;
export type GetGoalQueryResult = Apollo.QueryResult<GetGoalQuery, GetGoalQueryVariables>;
export const GetGoalsDocument = gql`
    query GetGoals($teamId: String!) {
  getGoals(teamId: $teamId) {
    ...GoalFragment
  }
}
    ${GoalFragmentFragmentDoc}`;

/**
 * __useGetGoalsQuery__
 *
 * To run a query within a React component, call `useGetGoalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoalsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetGoalsQuery(baseOptions: Apollo.QueryHookOptions<GetGoalsQuery, GetGoalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGoalsQuery, GetGoalsQueryVariables>(GetGoalsDocument, options);
      }
export function useGetGoalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGoalsQuery, GetGoalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGoalsQuery, GetGoalsQueryVariables>(GetGoalsDocument, options);
        }
export type GetGoalsQueryHookResult = ReturnType<typeof useGetGoalsQuery>;
export type GetGoalsLazyQueryHookResult = ReturnType<typeof useGetGoalsLazyQuery>;
export type GetGoalsQueryResult = Apollo.QueryResult<GetGoalsQuery, GetGoalsQueryVariables>;
export const ArchiveTaskDocument = gql`
    mutation ArchiveTask($archive: Boolean!, $taskId: String!) {
  archiveTask(archive: $archive, taskId: $taskId) {
    task {
      ...TaskFragment
    }
    errors {
      path
      message
    }
    success
  }
}
    ${TaskFragmentFragmentDoc}`;
export type ArchiveTaskMutationFn = Apollo.MutationFunction<ArchiveTaskMutation, ArchiveTaskMutationVariables>;

/**
 * __useArchiveTaskMutation__
 *
 * To run a mutation, you first call `useArchiveTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveTaskMutation, { data, loading, error }] = useArchiveTaskMutation({
 *   variables: {
 *      archive: // value for 'archive'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useArchiveTaskMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveTaskMutation, ArchiveTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveTaskMutation, ArchiveTaskMutationVariables>(ArchiveTaskDocument, options);
      }
export type ArchiveTaskMutationHookResult = ReturnType<typeof useArchiveTaskMutation>;
export type ArchiveTaskMutationResult = Apollo.MutationResult<ArchiveTaskMutation>;
export type ArchiveTaskMutationOptions = Apollo.BaseMutationOptions<ArchiveTaskMutation, ArchiveTaskMutationVariables>;
export const CreateTaskDocument = gql`
    mutation CreateTask($input: CreateTaskInput!) {
  createTask(input: $input) {
    task {
      ...TaskFragment
    }
    errors {
      path
      message
    }
    success
  }
}
    ${TaskFragmentFragmentDoc}`;
export type CreateTaskMutationFn = Apollo.MutationFunction<CreateTaskMutation, CreateTaskMutationVariables>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTaskDocument, options);
      }
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<CreateTaskMutation, CreateTaskMutationVariables>;
export const UpdateTaskDocument = gql`
    mutation UpdateTask($input: UpdateTaskInput!, $taskId: String!) {
  updateTask(input: $input, taskId: $taskId) {
    task {
      ...TaskFragment
    }
    errors {
      path
      message
    }
    success
  }
}
    ${TaskFragmentFragmentDoc}`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useUpdateTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument, options);
      }
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<UpdateTaskMutation, UpdateTaskMutationVariables>;
export const GetTaskDocument = gql`
    query GetTask($taskId: String!) {
  getTask(taskId: $taskId) {
    ...TaskFragment
  }
}
    ${TaskFragmentFragmentDoc}`;

/**
 * __useGetTaskQuery__
 *
 * To run a query within a React component, call `useGetTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetTaskQuery(baseOptions: Apollo.QueryHookOptions<GetTaskQuery, GetTaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskQuery, GetTaskQueryVariables>(GetTaskDocument, options);
      }
export function useGetTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskQuery, GetTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskQuery, GetTaskQueryVariables>(GetTaskDocument, options);
        }
export type GetTaskQueryHookResult = ReturnType<typeof useGetTaskQuery>;
export type GetTaskLazyQueryHookResult = ReturnType<typeof useGetTaskLazyQuery>;
export type GetTaskQueryResult = Apollo.QueryResult<GetTaskQuery, GetTaskQueryVariables>;
export const GetTasksDocument = gql`
    query GetTasks($teamId: String!, $includeArchived: Boolean) {
  getTasks(teamId: $teamId, includeArchived: $includeArchived) {
    ...TaskFragment
  }
}
    ${TaskFragmentFragmentDoc}`;

/**
 * __useGetTasksQuery__
 *
 * To run a query within a React component, call `useGetTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      includeArchived: // value for 'includeArchived'
 *   },
 * });
 */
export function useGetTasksQuery(baseOptions: Apollo.QueryHookOptions<GetTasksQuery, GetTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTasksQuery, GetTasksQueryVariables>(GetTasksDocument, options);
      }
export function useGetTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTasksQuery, GetTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTasksQuery, GetTasksQueryVariables>(GetTasksDocument, options);
        }
export type GetTasksQueryHookResult = ReturnType<typeof useGetTasksQuery>;
export type GetTasksLazyQueryHookResult = ReturnType<typeof useGetTasksLazyQuery>;
export type GetTasksQueryResult = Apollo.QueryResult<GetTasksQuery, GetTasksQueryVariables>;
export const AddTeamMemberDocument = gql`
    mutation AddTeamMember($input: AddTeamMemberInput!) {
  addTeamMember(input: $input) {
    ...TeamMembershipFragment
  }
}
    ${TeamMembershipFragmentFragmentDoc}`;
export type AddTeamMemberMutationFn = Apollo.MutationFunction<AddTeamMemberMutation, AddTeamMemberMutationVariables>;

/**
 * __useAddTeamMemberMutation__
 *
 * To run a mutation, you first call `useAddTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTeamMemberMutation, { data, loading, error }] = useAddTeamMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<AddTeamMemberMutation, AddTeamMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTeamMemberMutation, AddTeamMemberMutationVariables>(AddTeamMemberDocument, options);
      }
export type AddTeamMemberMutationHookResult = ReturnType<typeof useAddTeamMemberMutation>;
export type AddTeamMemberMutationResult = Apollo.MutationResult<AddTeamMemberMutation>;
export type AddTeamMemberMutationOptions = Apollo.BaseMutationOptions<AddTeamMemberMutation, AddTeamMemberMutationVariables>;
export const GetTeamMembersDocument = gql`
    query GetTeamMembers($teamId: String!) {
  getTeamMembers(teamId: $teamId) {
    ...TeamMembershipFragment
  }
}
    ${TeamMembershipFragmentFragmentDoc}`;

/**
 * __useGetTeamMembersQuery__
 *
 * To run a query within a React component, call `useGetTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamMembersQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetTeamMembersQuery(baseOptions: Apollo.QueryHookOptions<GetTeamMembersQuery, GetTeamMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamMembersQuery, GetTeamMembersQueryVariables>(GetTeamMembersDocument, options);
      }
export function useGetTeamMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamMembersQuery, GetTeamMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamMembersQuery, GetTeamMembersQueryVariables>(GetTeamMembersDocument, options);
        }
export type GetTeamMembersQueryHookResult = ReturnType<typeof useGetTeamMembersQuery>;
export type GetTeamMembersLazyQueryHookResult = ReturnType<typeof useGetTeamMembersLazyQuery>;
export type GetTeamMembersQueryResult = Apollo.QueryResult<GetTeamMembersQuery, GetTeamMembersQueryVariables>;
export const CreateTeamDocument = gql`
    mutation CreateTeam($name: String!) {
  createTeam(name: $name) {
    teamMembership {
      ...TeamMembershipFragment
    }
    errors {
      path
      message
    }
    success
  }
}
    ${TeamMembershipFragmentFragmentDoc}`;
export type CreateTeamMutationFn = Apollo.MutationFunction<CreateTeamMutation, CreateTeamMutationVariables>;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateTeamMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamMutation, CreateTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(CreateTeamDocument, options);
      }
export type CreateTeamMutationHookResult = ReturnType<typeof useCreateTeamMutation>;
export type CreateTeamMutationResult = Apollo.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<CreateTeamMutation, CreateTeamMutationVariables>;
export const GetTeamDocument = gql`
    query GetTeam($teamId: String!) {
  getTeam(teamId: $teamId) {
    ...TeamFragment
  }
}
    ${TeamFragmentFragmentDoc}`;

/**
 * __useGetTeamQuery__
 *
 * To run a query within a React component, call `useGetTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetTeamQuery(baseOptions: Apollo.QueryHookOptions<GetTeamQuery, GetTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamQuery, GetTeamQueryVariables>(GetTeamDocument, options);
      }
export function useGetTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamQuery, GetTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamQuery, GetTeamQueryVariables>(GetTeamDocument, options);
        }
export type GetTeamQueryHookResult = ReturnType<typeof useGetTeamQuery>;
export type GetTeamLazyQueryHookResult = ReturnType<typeof useGetTeamLazyQuery>;
export type GetTeamQueryResult = Apollo.QueryResult<GetTeamQuery, GetTeamQueryVariables>;
export const GetTeamsDocument = gql`
    query GetTeams($userId: String!) {
  getTeams(userId: $userId) {
    ...TeamMembershipFragment
  }
}
    ${TeamMembershipFragmentFragmentDoc}`;

/**
 * __useGetTeamsQuery__
 *
 * To run a query within a React component, call `useGetTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetTeamsQuery(baseOptions: Apollo.QueryHookOptions<GetTeamsQuery, GetTeamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamsQuery, GetTeamsQueryVariables>(GetTeamsDocument, options);
      }
export function useGetTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamsQuery, GetTeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamsQuery, GetTeamsQueryVariables>(GetTeamsDocument, options);
        }
export type GetTeamsQueryHookResult = ReturnType<typeof useGetTeamsQuery>;
export type GetTeamsLazyQueryHookResult = ReturnType<typeof useGetTeamsLazyQuery>;
export type GetTeamsQueryResult = Apollo.QueryResult<GetTeamsQuery, GetTeamsQueryVariables>;
export const LoginDocument = gql`
    mutation Login($input: LoginInput!) {
  login(input: $input) {
    ...StandardUserResponse
  }
}
    ${StandardUserResponseFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const RegisterDocument = gql`
    mutation Register($input: RegisterInput!) {
  register(input: $input) {
    ...StandardUserResponse
  }
}
    ${StandardUserResponseFragmentDoc}`;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
import { Button, TextInput } from 'dripsy';
import React, { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet, Text, View } from 'react-native';

import {
  GetTeamsDocument,
  GetTeamsQuery,
  useCreateTeamMutation,
} from '../components/generated/ApolloComponents';
import { theme } from '../utils/constants';

interface Props {
  userId: string;
}

export type CreateTeamFormData = {
  name: string;
};

export const CreateTeamForm: React.FC<Props> = ({ userId }) => {
  const styles = StyleSheet.create({
    input: {
      color: theme.colors.primary,
      borderColor: '#666666',
      borderWidth: 1,
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CreateTeamFormData>({
    defaultValues: {
      name: '',
    },
  });

  const [createTeam] = useCreateTeamMutation();
  const inputRef = useRef<typeof TextInput>(null);

  const onSubmit = async (data: CreateTeamFormData) => {
    const response = await createTeam({
      variables: { name: data.name },
      update: (cache, { data }) => {
        const currentTeams = cache.readQuery<GetTeamsQuery>({
          query: GetTeamsDocument,
          variables: {
            userId,
          },
        });
        cache.writeQuery<GetTeamsQuery>({
          query: GetTeamsDocument,
          variables: {
            userId,
          },
          data: {
            ...currentTeams,
            __typename: 'Query',
            getTeams: [
              ...(currentTeams?.getTeams || []),
              data?.createTeam.teamMembership!,
            ],
          },
        });
      },
    });
    if (response.data?.createTeam.errors?.length) {
      console.error(response.data.createTeam.errors);
    } else if (response.data?.createTeam.teamMembership) {
      reset();
      if (inputRef.current != null) {
        // @ts-ignore
        inputRef.current.focus();
      }
    }
  };
  return (
    <View style={{ flexDirection: 'row' }}>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            ref={inputRef}
            style={styles.input}
            onBlur={onBlur}
            onChangeText={(value) => onChange(value)}
            placeholder={'team name'}
            value={value}
            onSubmitEditing={handleSubmit(onSubmit)}
          />
        )}
        name="name"
        rules={{ required: true }}
        defaultValue=""
      />
      {errors.name && (
        <Text style={{ color: theme.colors.red }}>Name is required.</Text>
      )}

      <Button
        color={theme.colors.blue}
        title="Add team"
        onPress={handleSubmit(onSubmit)}
      />
    </View>
  );
};

import React from 'react';
import { Text, View } from 'react-native';

import { useGetTeamsQuery } from '../components/generated/ApolloComponents';
import { CreateTeamForm } from '../forms/CreateTeam';
import { LoadingScreen } from '../screens/LoadingScreen';
import { TeamsList } from '../sections/profile/TeamsList';
import { theme } from '../utils/constants';

interface Props {
  userId: string;
}

export const TeamsContainer: React.FC<Props> = ({ userId }) => {
  const { data, loading, error } = useGetTeamsQuery({ variables: { userId } });
  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    return (
      <View>
        <Text style={{ color: theme.colors.primary }}>
          Error {error.message}
        </Text>
      </View>
    );
  }

  if (!data) {
    return (
      <View>
        <Text style={{ color: theme.colors.primary }}>No data</Text>
        <CreateTeamForm userId={userId} />
      </View>
    );
  }

  return (
    <View>
      <TeamsList teams={data.getTeams} />
      <CreateTeamForm userId={userId} />
    </View>
  );
};

import React from 'react';
import { Button, StyleSheet, Text, TextInput, View } from 'react-native';
import { useForm, Controller } from 'react-hook-form';

import {
  MeDocument,
  MeQuery,
  useRegisterMutation,
} from '../components/generated/ApolloComponents';
import { theme } from '../utils/constants';

interface Props {}

export type RegisterFormData = {
  email: string;
  username: string;
  password: string;
};

export const RegisterForm: React.FC<Props> = () => {
  const [register] = useRegisterMutation();

  const styles = StyleSheet.create({
    input: {
      color: theme.colors.primary,
      borderColor: '#666666',
      borderWidth: 1,
    },
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterFormData>();

  const onSubmit = async (data: RegisterFormData) => {
    const response = await register({
      variables: { input: data },
      update: (cache, { data }) => {
        cache.writeQuery<MeQuery>({
          query: MeDocument,
          data: {
            __typename: 'Query',
            me: data?.register.user,
          },
        });
      },
    });

    if (response.data?.register.errors) {
      console.error(response.data.register.errors);
    } else if (response.data?.register.user) {
      // worked
      // TODO: implement routing
      // router.push('/');
      console.log(response.data.register);
    }
  };

  return (
    <View style={{ maxWidth: 400, alignSelf: 'center', minWidth: 300 }}>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            style={styles.input}
            onBlur={onBlur}
            onChangeText={(value) => onChange(value)}
            value={value}
            textContentType="emailAddress"
            placeholder={'email address'}
          />
        )}
        name="email"
        rules={{ required: true }}
        defaultValue=""
      />
      {errors.email && (
        <Text style={{ color: theme.colors.red }}>Email is required.</Text>
      )}

      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            style={styles.input}
            onBlur={onBlur}
            onChangeText={(value) => onChange(value)}
            value={value}
            placeholder={'username'}
            autoCapitalize="none"
            textContentType="username"
          />
        )}
        name="username"
        defaultValue=""
        rules={{ required: true }}
      />
      {errors.username && (
        <Text style={{ color: theme.colors.red }}>Username is required.</Text>
      )}

      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            style={styles.input}
            onBlur={onBlur}
            secureTextEntry
            onChangeText={(value) => onChange(value)}
            value={value}
            placeholder={'password'}
            onSubmitEditing={handleSubmit(onSubmit)}
            textContentType="newPassword"
            passwordRules="minlength: 6; required: lower; required: upper; required: digit;"
          />
        )}
        name="password"
        defaultValue=""
        rules={{ required: true }}
      />
      {errors.password && (
        <Text style={{ color: theme.colors.red }}>Password is required.</Text>
      )}

      <Button title="Register" onPress={handleSubmit(onSubmit)} />
    </View>
  );
};

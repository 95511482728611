import React, { useEffect } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { useGetTasksQuery } from '../components/generated/ApolloComponents';
import { CreateTaskForm } from '../forms/CreateTask';
import { LoadingScreen } from '../screens/LoadingScreen';
import { TasksSection } from '../sections/dashboard/TasksSection';
import { theme } from '../utils/constants';

interface Props {
  teamId: string;
}

export const TasksContainer: React.FC<Props> = ({ teamId }) => {
  useEffect(() => {}, []);
  const { data, loading, error } = useGetTasksQuery({
    variables: { teamId },
  });

  if (loading || !data) {
    return <LoadingScreen />;
  }

  if (error) {
    return (
      <View>
        <Text style={{ color: theme.colors.red }}>Error {error.message}</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <TasksSection tasks={data.getTasks} />
      <CreateTaskForm teamId={teamId} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderWidth: 2,
    borderColor: 'silver',
    width: '100%',
    padding: 1,
    alignSelf: 'center',
  },
});

import React from 'react';
import { Link } from '@react-navigation/native';
import { Text, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

import {
  Team,
  TeamFragmentFragment,
  TeamMembership,
  TeamMembershipFragmentFragment,
} from '../../components/generated/ApolloComponents';
import { theme } from '../../utils/constants';
import { Hoverable } from 'react-native-web-hooks';

interface Props {
  teams: TeamMembershipFragmentFragment[];
}

export const TeamsList: React.FC<Props> = ({ teams }) => {
  console.log('TEAMS', teams);
  const TeamItem = (team: TeamMembershipFragmentFragment) => (
    <Hoverable>
      {(isHovered) => (
        <View style={{ borderColor: 'silver', borderWidth: isHovered ? 1 : 0 }}>
          <Link to={`/${team.team.id}/dashboard`}>
            <Text style={{ color: theme.colors.primary }}>
              {team.team.name}
            </Text>
            <Text style={{ color: theme.colors.primary }}>
              {' '}
              {team.permissionLevel}
            </Text>
          </Link>
        </View>
      )}
    </Hoverable>
  );
  return (
    <ScrollView style={{ borderColor: 'silver', borderWidth: 2 }}>
      {teams.map((t) => (
        <TeamItem key={t.id} {...t} />
      ))}
    </ScrollView>
  );
};

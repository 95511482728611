import { View, A } from 'dripsy';
import React, { useState } from 'react';
import { Text } from 'react-native';
import { CheckBox } from 'react-native-web';
import { Hoverable } from 'react-native-web-hooks';
import { Ionicons } from '@expo/vector-icons';

import {
  TaskFragmentFragment,
  useArchiveTaskMutation,
  useUpdateTaskMutation,
} from '../../components/generated/ApolloComponents';
import { AddLinkToTask } from '../../forms/AddLinkToTask';
import { AssignTaskToUser } from '../../forms/AssignTaskToUser';
import { UpdateTaskDesciptionForm } from '../../forms/UpdateTaskDescription';
import { theme } from '../../utils/constants';

interface TaskItemProps {
  task: TaskFragmentFragment;
}

export const TaskItem: React.FC<TaskItemProps> = ({ task }) => {
  const [updateTask] = useUpdateTaskMutation();
  const [archiveTask] = useArchiveTaskMutation();
  const [displayLinkInput, setDisplayLinkInput] = useState(false);
  const [displayDescriptionInput, setDisplayDescriptionInput] = useState(false);
  const [
    displayUserAssignmentPicker,
    setDisplayUserAssignmentPicker,
  ] = useState(false);
  return (
    <Hoverable>
      {(isHovered) => (
        <View
          style={{
            borderWidth: isHovered ? 1 : 0,
            borderColor: 'silver',
            opacity: task.isArchived ? 0.6 : 1,
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            <CheckBox
              value={task.isComplete}
              onClick={async () => {
                await updateTask({
                  variables: {
                    taskId: task.id,
                    input: {
                      isComplete: !task.isComplete,
                      assignedUserId: task.assignedUserId,
                    },
                  },
                });
              }}
            />
            {!displayDescriptionInput ? (
              <Text
                onPress={() => setDisplayDescriptionInput(true)}
                style={{ color: theme.colors.primary, marginLeft: 4 }}
              >
                {task.description}
              </Text>
            ) : (
              <UpdateTaskDesciptionForm
                task={task}
                displayDescriptionInput={displayDescriptionInput}
                setDisplayDescriptionInput={setDisplayDescriptionInput}
              />
            )}
            {task.assignedUser ? (
              <>
                {!displayUserAssignmentPicker ? (
                  <Text
                    style={{ color: theme.colors.green, marginLeft: 'auto' }}
                    onPress={() => setDisplayUserAssignmentPicker(true)}
                  >
                    Assigned to {task.assignedUser.email}
                  </Text>
                ) : (
                  <AssignTaskToUser
                    task={task}
                    setDisplayUserAssignmentPicker={
                      setDisplayUserAssignmentPicker
                    }
                  />
                )}
              </>
            ) : (
              <>
                {!displayUserAssignmentPicker ? (
                  <Text
                    onPress={() => setDisplayUserAssignmentPicker(true)}
                    style={{ color: theme.colors.green, marginLeft: 'auto' }}
                  >
                    Assign
                  </Text>
                ) : (
                  <AssignTaskToUser
                    task={task}
                    setDisplayUserAssignmentPicker={
                      setDisplayUserAssignmentPicker
                    }
                  />
                )}
              </>
            )}
            <Hoverable>
              {(isHovered) => (
                <Text
                  style={{
                    color: task.isArchived
                      ? theme.colors.secondary
                      : theme.colors.red,
                    opacity: 0.5,
                    textDecorationLine: isHovered ? 'underline' : 'none',
                  }}
                  onPress={() =>
                    archiveTask({
                      variables: {
                        archive: !task.isArchived,
                        taskId: task.id,
                      },
                    })
                  }
                >
                  {task.isArchived ? (
                    'Restore'
                  ) : (
                    <Ionicons
                      name="ios-trash-outline"
                      size={18}
                      color={theme.colors.red}
                    />
                  )}
                </Text>
              )}
            </Hoverable>
          </View>
          {task.link ? (
            <View style={{ flexDirection: 'row' }}>
              {!displayLinkInput ? (
                <>
                  <Hoverable>
                    {(isHovered) => (
                      //@ts-ignore
                      <A
                        href={`${task.link}`}
                        target="_blank"
                        accessibilityRole="link"
                        style={{
                          color: theme.colors.secondary,
                          textDecorationLine: isHovered ? 'underline' : 'none',
                        }}
                      >
                        {task.link}
                      </A>
                    )}
                  </Hoverable>
                  <Text
                    onPress={() => setDisplayLinkInput(true)}
                    style={{
                      color: theme.colors.secondary,
                      marginLeft: 'auto',
                    }}
                  >
                    Edit link
                  </Text>
                </>
              ) : (
                <AddLinkToTask
                  displayLinkInput={displayLinkInput}
                  setDisplayLinkInput={setDisplayLinkInput}
                  task={task}
                />
              )}
            </View>
          ) : !displayLinkInput ? (
            <Text
              onPress={() => setDisplayLinkInput(true)}
              style={{ color: 'gray' }}
            >
              + link
            </Text>
          ) : (
            <AddLinkToTask
              displayLinkInput={displayLinkInput}
              setDisplayLinkInput={setDisplayLinkInput}
              task={task}
            />
          )}
        </View>
      )}
    </Hoverable>
  );
};

import { Link } from '@react-navigation/native';
import React from 'react';

import { Screen } from '../components/common/Screen';
import { LoginForm } from '../forms/LoginForm';
import { theme } from '../utils/constants';

interface Props {}

export const LoginScreen: React.FC<Props> = () => {
  return (
    <Screen style={{ justifyContent: 'center', alignItems: 'center' }}>
      <LoginForm />
      <Link style={{ color: theme.colors.primary }} to={'/register'}>
        Need an account? Register here
      </Link>
    </Screen>
  );
};

import React from 'react';
import { SafeAreaView, StyleSheet, ViewProps } from 'react-native';
import { theme } from '../../utils/constants';

interface Props extends ViewProps {}

export const Screen: React.FC<Props> = ({ children, style, ...rest }) => {
  return (
    <SafeAreaView style={[styles.container, style]} {...rest}>
      {children}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.colors.background,
  },
});

import { View } from 'dripsy';
import React from 'react';
import { Text } from 'react-native';

import { Screen } from '../components/common/Screen';
import { theme } from '../utils/constants';

interface Props {}

export const LoadingScreen: React.FC<Props> = () => {
  return (
    <Screen>
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
        }}
      >
        <Text style={{ color: theme.colors.primary }}>Practical Magic</Text>
      </View>
    </Screen>
  );
};

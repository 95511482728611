import { ApolloClient, InMemoryCache } from '@apollo/client';

let uri;
const nodeEnv = process.env.NODE_ENV;

switch (nodeEnv) {
  case 'development':
    console.log('__DEV__');
    uri = 'http://192.168.0.4:4000/graphql';
    break;
  case 'staging':
    console.log('__STAGING__');
    uri = 'https://api.practicalmagic.app/graphql';
    break;
  case 'production':
    console.log('__PRODUCTION__');
    uri = 'https://api.practicalmagic.app/graphql';
    break;
  default:
    console.log('__BLACK HOLE__');
    break;
}

export const client = new ApolloClient({
  uri,
  credentials: 'include',
  cache: new InMemoryCache(),
});

import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useForm, Controller } from 'react-hook-form';
import { Button, TextInput } from 'dripsy';
import { useApolloClient } from '@apollo/client';

import {
  MeDocument,
  MeQuery,
  useLoginMutation,
} from '../components/generated/ApolloComponents';
import { theme } from '../utils/constants';

interface Props {}

export type LoginFormData = {
  usernameOrEmail: string;
  password: string;
};

export const LoginForm: React.FC<Props> = () => {
  const apolloClient = useApolloClient();
  const styles = StyleSheet.create({
    input: {
      color: theme.colors.primary,
      borderColor: '#666666',
      borderWidth: 1,
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormData>();

  const [login] = useLoginMutation();

  const onSubmit = async (data: LoginFormData) => {
    // make sure to start with a fresh cache
    apolloClient.resetStore();
    const response = await login({
      variables: { input: data },
      update: (cache, { data }) => {
        cache.writeQuery<MeQuery>({
          query: MeDocument,
          data: {
            __typename: 'Query',
            me: data?.login.user,
          },
        });
      },
    });
    // if (response.data?.login.success) {
    //   router.push('/');
    // }
    console.log('res', response.data?.login);
    if (response.data?.login.errors?.length) {
      console.error(response.data.login.errors);
    } else if (response.data?.login.user) {
      console.log('success');
      // if (typeof router.query.next === 'string') {
      //   router.push(router.query.next);
      // } else {
      //   // worked
      //   router.push('/');
      // }
    }
  };

  return (
    <View style={{ maxWidth: 400, alignSelf: 'center', minWidth: 300 }}>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            style={styles.input}
            onBlur={onBlur}
            onChangeText={(value) => onChange(value)}
            value={value}
            textContentType="emailAddress"
            autoCapitalize="none"
            placeholder={'email or username'}
          />
        )}
        name="usernameOrEmail"
        rules={{ required: true }}
        defaultValue=""
      />
      {errors.usernameOrEmail && (
        <Text style={{ color: theme.colors.red }}>
          Email or username is required.
        </Text>
      )}

      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            style={styles.input}
            onBlur={onBlur}
            secureTextEntry
            onChangeText={(value) => onChange(value)}
            textContentType="password"
            placeholder={'password'}
            value={value}
            onSubmitEditing={handleSubmit(onSubmit)}
          />
        )}
        name="password"
        rules={{ required: true }}
        defaultValue=""
      />
      {errors.password && (
        <Text style={{ color: theme.colors.red }}>Password is required.</Text>
      )}

      <Button title="Login" onPress={handleSubmit(onSubmit)} />
    </View>
  );
};

import React from 'react';
import { StatusBar } from 'expo-status-bar';
import {
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  View,
  ScrollView,
  Keyboard,
} from 'react-native';
import { RouteProp } from '@react-navigation/native';

import { Screen } from '../components/common/Screen';
import { StackParamList } from '../navigators';
import { Header } from '../components/common/Header';
import { GoalsContainer } from '../containers/GoalsContainer';
import { TasksContainer } from '../containers/TasksContainer';
import { TeamMembersContainer } from '../containers/TeamMembersContainer';

interface Props {
  route: RouteProp<StackParamList, 'Dashboard'>;
}

export const DashboardScreen: React.FC<Props> = ({ route }) => {
  // const apolloClient = useApolloClient();

  // const meData = apolloClient.readQuery({ query: MeDocument });

  // if (meData) {
  //   console.log('MEDATA', meData);
  // }
  // useEffect(() => {}, [route]);

  return (
    <Screen>
      {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}> */}
      <KeyboardAvoidingView
        style={{ flex: 1, flexDirection: 'column', justifyContent: 'center' }}
        behavior="padding"
        enabled
        keyboardVerticalOffset={100}
      >
        <ScrollView
          style={{ paddingHorizontal: 10 }}
          keyboardShouldPersistTaps="handled"
        >
          <View style={{ height: 5 }} />
          <Header />
          <View style={{ height: 5 }} />
          <GoalsContainer teamId={route.params.teamId} />
          <View style={{ height: 10 }} />
          <TasksContainer teamId={route.params.teamId} />
          <View style={{ height: 10 }} />
          <TeamMembersContainer teamId={route.params.teamId} />
          <View style={{ height: 10 }} />
          <StatusBar style="auto" />
        </ScrollView>
      </KeyboardAvoidingView>
      {/* </TouchableWithoutFeedback> */}
    </Screen>
  );
};

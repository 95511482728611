import React, { useEffect, useRef } from 'react';
import { Button, TextInput, Text } from 'dripsy';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';

import { theme } from '../utils/constants';
import {
  TaskFragmentFragment,
  useUpdateTaskMutation,
} from '../components/generated/ApolloComponents';

interface Props {
  task: TaskFragmentFragment;
  setDisplayLinkInput: React.Dispatch<React.SetStateAction<boolean>>;
  displayLinkInput: boolean;
}

export type AddLinkToTaskFormData = {
  link: string;
};

export const AddLinkToTask: React.FC<Props> = ({
  task,
  setDisplayLinkInput,
  displayLinkInput,
}) => {
  const styles = StyleSheet.create({
    input: {
      color: theme.colors.primary,
      borderColor: '#666666',
      borderWidth: 1,
      width: '100%',
    },
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<AddLinkToTaskFormData>({
    defaultValues: {
      link: task.link ? task.link : '',
    },
  });

  const [updateTask] = useUpdateTaskMutation();
  const inputRef = useRef<typeof TextInput>(null);

  useEffect(() => {
    if (inputRef.current !== null && displayLinkInput === true) {
      // @ts-ignore
      inputRef.current.focus();
    }
  }, [displayLinkInput]);

  const onSubmit = async (data: AddLinkToTaskFormData) => {
    const res = await updateTask({
      variables: {
        taskId: task.id,
        input: {
          link: data.link,
          assignedUserId: task.assignedUserId,
        },
      },
    });
    if (res.data?.updateTask.success) {
      reset();
      setDisplayLinkInput(false);
    }
  };

  return (
    <View style={{ flexDirection: 'row', width: '100%', flex: 1 }}>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            ref={inputRef}
            style={styles.input}
            onBlur={onBlur}
            onChangeText={(value) => onChange(value)}
            placeholder={'task link'}
            value={value}
            onSubmitEditing={handleSubmit(onSubmit)}
            onKeyPress={(e) => {
              if (e.nativeEvent.key === 'Escape') {
                reset();
                setDisplayLinkInput(false);
              }
            }}
          />
        )}
        name="link"
        rules={{ required: false }}
        defaultValue={task.link}
      />
      {errors.link && (
        <Text style={{ color: theme.colors.red }}>Link is optional.</Text>
      )}
      <Button
        color={theme.colors.blue}
        title="Save link"
        onPress={handleSubmit(onSubmit)}
      />
      <Text
        onPress={() => {
          reset();
          setDisplayLinkInput(false);
        }}
        style={{
          color: theme.colors.red,
          fontSize: 24,
          fontFamily: 'System',
          paddingHorizontal: 10,
        }}
      >
        X
      </Text>
    </View>
  );
};

import React from 'react';
import { LinkingOptions, NavigationContainer } from '@react-navigation/native';
import {
  CardStyleInterpolators,
  createStackNavigator,
} from '@react-navigation/stack';
import * as Linking from 'expo-linking';

import { useMeQuery } from '../components/generated/ApolloComponents';
import { DashboardScreen } from '../screens/DashboardScreen';
import { LoginScreen } from '../screens/LoginScreen';
import { ProfileScreen } from '../screens/ProfileScreen';
import { RegisterScreen } from '../screens/RegisterScreen';
import { theme } from '../utils/constants';
import { LoadingScreen } from '../screens/LoadingScreen';

export type StackParamList = {
  Login: undefined;
  Register: undefined;
  Dashboard: { teamId: string };
  Profile: { userId: string };
};

const Stack = createStackNavigator<StackParamList>();

const prefix = Linking.createURL('/');

const Navigator = () => {
  const { data, loading, error } = useMeQuery();
  console.log('DATA', data);

  const linking: LinkingOptions = {
    enabled: true,
    prefixes: [
      'http://192.168.0.4:19006',
      'https://*.practicalmagic.app',
      'https://practicalmagic.app',
      'practicalmagic://',
      prefix,
    ],
    config: {
      screens: {
        Home: {
          path: '',
        },
        Login: {
          path: 'login',
        },
        Register: {
          path: 'register',
        },
        Profile: {
          path: ':userId/profile',
        },
        Dashboard: {
          path: ':teamId/dashboard',
        },
      },
    },
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <NavigationContainer linking={linking} fallback={<LoadingScreen />}>
      <Stack.Navigator
        initialRouteName={data?.me?.id ? 'Profile' : 'Login'}
        screenOptions={{
          headerShown: false,
          headerStyle: {
            backgroundColor: theme.colors.background,
            borderBottomWidth: 0,
            shadowRadius: 0,
            shadowOffset: {
              height: 0,
              width: 0,
            },
            elevation: 0,
          },
          headerTintColor: theme.colors.primary,
          animationEnabled: true,
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
        }}
      >
        {data?.me?.id ? (
          <>
            <Stack.Screen
              name="Profile"
              component={ProfileScreen}
              initialParams={{ userId: `${data.me.id}` }}
            />
            <Stack.Screen
              name="Dashboard"
              component={DashboardScreen}
              initialParams={{ teamId: `${data.me.defaultTeamId}` }}
            />
          </>
        ) : (
          <>
            <Stack.Screen name="Login" component={LoginScreen} />
            <Stack.Screen name="Register" component={RegisterScreen} />
          </>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default Navigator;

import { View } from 'dripsy';
import React, { useState } from 'react';
import { StyleSheet, Text } from 'react-native';
import { Hoverable } from 'react-native-web-hooks';
import { CheckBox } from 'react-native-web';
import { Ionicons } from '@expo/vector-icons';

import {
  GoalFragmentFragment,
  useArchiveGoalMutation,
  useUpdateGoalMutation,
} from '../../components/generated/ApolloComponents';
import { theme } from '../../utils/constants';
import { UpdateGoalDesciptionForm } from '../../forms/UpdateGoalDescription';

interface Props {
  goals: GoalFragmentFragment[];
}

interface GoalItemProps {
  goal: GoalFragmentFragment;
}

const GoalItem: React.FC<GoalItemProps> = ({ goal }) => {
  const [updateGoal] = useUpdateGoalMutation();
  const [archiveGoal] = useArchiveGoalMutation();
  const [
    displayGoalDescriptionInput,
    setDisplayGoalDescriptionInput,
  ] = useState(false);
  return (
    <Hoverable>
      {(isHovered) => (
        <View
          style={{
            flexDirection: 'row',
            borderColor: 'silver',
            borderWidth: isHovered ? 1 : 0,
          }}
        >
          <CheckBox
            value={goal.isComplete}
            onClick={async () => {
              await updateGoal({
                variables: {
                  goalId: goal.id,
                  isComplete: !goal.isComplete,
                },
              });
            }}
          />
          {!displayGoalDescriptionInput ? (
            <>
              <Text
                onPress={() => setDisplayGoalDescriptionInput(true)}
                style={{ color: theme.colors.primary }}
              >
                {goal.description}
              </Text>
              <View style={{ marginLeft: 'auto' }}>
                <Hoverable>
                  {(isHovered) => (
                    <Text
                      style={{
                        color: goal.isArchived
                          ? theme.colors.secondary
                          : theme.colors.red,
                        opacity: 0.5,
                        textDecorationLine: isHovered ? 'underline' : 'none',
                      }}
                      onPress={() =>
                        archiveGoal({
                          variables: {
                            archive: !goal.isArchived,
                            goalId: goal.id,
                          },
                        })
                      }
                    >
                      {goal.isArchived ? (
                        'Restore'
                      ) : (
                        <Ionicons
                          name="ios-trash-outline"
                          size={18}
                          color={theme.colors.red}
                        />
                      )}
                    </Text>
                  )}
                </Hoverable>
              </View>
            </>
          ) : (
            <UpdateGoalDesciptionForm
              goal={goal}
              displayGoalDescriptionInput={displayGoalDescriptionInput}
              setDisplayGoalDescriptionInput={setDisplayGoalDescriptionInput}
            />
          )}
        </View>
      )}
    </Hoverable>
  );
};
export const GoalsSection: React.FC<Props> = ({ goals }) => {
  return (
    <View>
      {goals
        ? goals
            .filter((g) => !g.isArchived)
            .map((g) => <GoalItem key={g.id} goal={g} />)
        : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderWidth: 2,
    borderColor: 'silver',
    width: '100%',
    padding: 1,
    alignSelf: 'center',
  },
  baseText: {
    color: '#FFBF00',
    fontFamily: 'System',
  },
  bodyText: {
    fontSize: 18,
  },
  headerText: {
    fontSize: 18,
  },
});
